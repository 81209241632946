.container {
    border: 1px solid #eaeaea;
    width: 20px;
    height: 800px;
    margin: auto;
    position: relative;
    border-radius: 10px;
    will-change: transform;
}

.progress {
    width: 100%;
    height: 1px;
    color: transparent;
    position: sticky;
    top: 430px;
    transform: translate3d(0, 0, 0);
}

.progressFill {
    width: 100%;
    background-color: rgba(105, 91, 255, 0.721);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    min-height: 20px;
    transition: height 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 10px;
    will-change: transform, height;
    transform: translateZ(0);
    backface-visibility: hidden;
}

.stage {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 60px;
    left: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 3px solid rgb(105, 91, 255);
    border-radius: 20px;
    font-size: 22px;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.visible {
    opacity: 1;
}

.stage span {
    color: rgb(105, 91, 255) !important;
}

.contentContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.lottieComponent {
    opacity: 0;
    transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
    position: sticky;
    top: 200px;
    width: 100%;
}

.rightContent,
.leftContent {
    width: 50%;
    display: flex;
    justify-content: center;
}

@media (max-width: 820px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .lottieComponent {
        margin-bottom: 100px !important;
        height: auto !important;
    }
}

@media (min-width: 821px) {
    .mobile {
        display: none;
    }
}