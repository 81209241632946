.mainCard {
    /* background: linear-gradient(to bottom right, rgb(99 102 241), rgb(147 51 234)); */
    background-color: rgb(104, 91, 255);
    padding: 2.5rem;
    margin: 0 2rem 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

  .cardContent {
    max-width: 42rem;
    margin: 0 auto;
    text-align: center;
  }

  .cardInner {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .mainCard {
        margin: 0 1.5rem 1.5rem;
        padding: 2rem 1.5rem;
      }    
  }
  