@media (max-width: 1000px) {
    .navbarScrolled {
        border-radius: 0 !important;
        background-color: white !important;        
        opacity: 1 !important;
    }

    .navbarNotScrolled {
        background-color: white !important;
    }

    .menu {
        background-color: white !important;
    }
}

/* Desktop */
@media (min-width: 1000px) {
    .hideDesktop {
        display: none !important;
    }
    
    .hamburger {
        display: none;
    }

    .navLinks {
        flex-direction: row;
        align-items: center;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        position: static;
        top: auto;
        width: auto;
        left: 0;
        transform: none;
        border: none;
    }    
}

/* Mobile */
@media (max-width: 1000px) {
    .hideMobile {
        display: none;
    }
    
    .navLinks {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0 0 25px 25px;
        padding: 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 100%;
        width: calc(100%);
        left: 0px;
        transform: none;
        border: 1px solid rgb(211, 218, 230);
    }

    .navLinksMenuOpen {
        display: flex;
    }
}
