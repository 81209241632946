@media (max-width: 1350px) and (min-width: 666px) {
  .heroText {
    justify-content: center !important;
    max-width: 800px !important;
    text-align: center !important;
  }

  .heroButtons {
    align-items: center !important;
  }
}

/* Mobile */
@media (max-width: 1350px) {
  .heroSection {
    flex-direction: column !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  .heroText {
    max-width: 100%;
    margin-left: 0;
    padding: 24px;
  }

  .heroVideo {
    position: static;
    left: auto;
    margin-top: 30px;
  }
}

/* Desktop */
@media (min-width: 1351px) {
  .heroSection {
    flex-direction: row !important;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .heroText {
    max-width: 58%;
    margin-left: -100px;
    padding: 50px;
  }

  .heroButtons {
    align-items: flex-start !important;
    text-align: left !important;
  }

  .heroVideo {
    position: absolute;
    left: 50%;
    margin-top: 0;
  }
}
