@media (max-width: 820px) {
    .container {
        overflow-x: hidden;
    }
} 

/* Mobile */
@media (max-width: 1200px) {
    .wrapperWindmill {
        display: none;
    }

    .callToActionContainer {
        padding-top: 0;
    }
}

/* Desktop */
@media (min-width: 1201px) {
    .wrapperWindmill {
        display: block;
    }

    .callToActionContainer {
        padding-top: 15px;
    }
} 